.service-proven-approach {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    .item {
        &:not(:last-child) {
            &::after {
                margin-left: 50%;
                margin-top: 10px;
                margin-bottom: 10px;
                content: '';
                width: 100px;
                height: 100px;
                border-left: 2px solid #C1C2C5;
            }
        }
        width: 100%;
        .item-center, .item-left, .item-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .item-center {
            justify-content: center;
            .item-icon-wrapper {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                border: 2px solid #C1C2C5;
                padding: 4px;
                display: flex;
                align-items: center;
                .item-icon {
                    background-color: #EB1F27;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .item-info {
            display: flex;
            flex-direction: column;
            max-width: 400px;
            font-size: 18px;
            h5 {
                font-weight: 700;
            }
            p {
                font-weight: 400;
            }
        }
        .item-left {
            justify-content: end;
            .item-info {
                align-items: end;
                h5, p {
                    text-align: right;
                }
            }
        }
        .item-right {
            justify-content: start;
            .item-info {
                align-items: start;
            }
        }
    }
}
