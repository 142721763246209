/* Section Service Head */
.service-head .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/* Section Service Head Content */
.service-head-content .container {
  display: flex;
  flex-wrap: wrap;
}

/* Section Service Module */
.service-module .container,
.service-client .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.what-we-can-do,
.whom-we-are-serving-in {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 30%;
}

.modules,
.clients {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 70%;
  justify-content: flex-end;
  gap: 50px;
}

.modules-left,
.modules-right,
.clients-left,
.clients-right {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 40%;
  gap: 50px;
}

.module,
.client {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .service-module .container,
  .service-client .container {
    flex-direction: column;
  }

  .what-we-can-do,
  .whom-we-are-serving-in {
    align-items: center;
    flex-basis: 100%;
    margin-bottom: 100px;
  }

  .modules,
  .clients {
    flex-direction: column;
    flex-basis: 100%;
    justify-content: space-between;
    gap: 0px;
  }

  .modules-left,
  .modules-right,
  .clients-left,
  .clients-right {
    flex-basis: 100%;
  }

  .module,
  .client {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .service-module h1::after,
  .service-client h1::after {
    left: 35%;
    right: 35%;
    width: 30%;
    margin-top: 3.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

/* Section Contact Us */
.contact-us .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .contact-us .container {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    text-align: center;
  }
}