.service-stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: center;

    .item {
        max-width: 400px;
        min-width: 300px;
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        h1 {
            font-size: 60px;
            font-weight: 800;
        }
        h5 {
            font-weight: 500;
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 767px) {
    .service-stats {
        flex-direction: column;
    }   
}