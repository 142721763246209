.mission-vision-margin {
    margin-top: 80px;
    margin-bottom: -80px;
}

.mission-vision-subhead {
    text-align: center;
}

.mission-vision-title {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
}

.mission-vision-showmore-btn {
    border: none;
    background: transparent;
}

.mission-vision-showmore-btn-icon-margin {
    margin-left: 20px;
}

.mission-vision-container {
    display: flex;
    justify-content: space-between;
}

.mission-vision-left,
.mission-vision-right {
    flex-basis: 40%;
}

.mission,
.vision {
    display: flex;
    gap: 50px;
}

@media only screen and (max-width: 767px) {
    .mission-vision-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}