/* Section Head */
.contact-head .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contact-head .container {
  text-align: center;
}

/* Section Form and Map */

.contact-form-map .contact-form-wrapper {
  border-radius: 10px;
  border: 1px solid #DBDBDB;
}

.contact-form {
  padding: 70px 70px;
  flex-basis: 50%;
}

.contact-form label {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.contact-form input,
.contact-form textarea {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #DBDBDB;
}

.contact-form button.form-submit {
  background-color: #EB1F27;
  color: #FFF;
  border-radius: 4px;
  border: none;
  padding: 15px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.contact-map {
  flex-basis: 50%;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {

  .contact-form {
    padding: 20px;
    flex-basis: 50%;
  }

  .contact-form,
  .contact-map {
    flex-basis: 100%;
  }

  .contact-map iframe {
    height: 100vh;
  }
}

/* Section Address */
.contact-address .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #DBDBDB;
  padding: 40px;
  gap: 20px;
}

.contact-address .container .address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  gap: 20px;
  border: 1px solid #E9E9E9;
  min-width: 275px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .contact-address .container {
    align-items: center;
    gap: 50px;
  }

  .contact-address .container div {
    flex: 100%;
  }
}


