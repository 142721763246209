html,
body {
  /* width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px; */
  overflow-x: hidden;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

/* @font-face {
  font-family: 'Inter';
  src: url('../public/font/merriweather/Inter-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url('../public/font/vollkorn/Inter-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url('../public/font/trueno/TruenoRg.otf') format('opentype');
} */

/* @font-face {
  font-family: 'Eurostile';
  src: url('../public/font/eurostile/Eurostile-Unicase-LT-W04-Rg.otf') format('opentype');
} */

/* :root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Ubuntu";
  --font-secondary: "Work Sans", sans-serif;
} */

/* :root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Work Sans", sans-serif;
} */

/* Colors */
:root {
  --color-default: #364d59;
  --color-primary: #EB1F27;
  --color-secondary: #52565e;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: 'Inter' !important;
  color: var(--color-default);
  overflow-x: hidden;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: #e42c4c;
  text-decoration: none;
}

h2,
h4,
h5,
h6 {
  font-family: 'Inter';
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f6f7;
}

.section-header {
  text-align: center;
  /* padding-bottom: 70px; */
}

.section-header h2 {
  position: relative;
  color: #9e9fa1;
  /* text-transform: uppercase; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.content h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 144.5%;
  /* 72.25px */
}

.content h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
}

.content p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* text-align: center; */
}

.section-header h2:before,
.section-header h2:after {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--color-primary);
  display: inline-block;
  border-radius: 100%;
}

.section-header h2:before {
  margin: 0 10px 5px 0;
  vertical-align: middle;
}

.section-header h2:after {
  margin: 0 0 5px 10px;
  vertical-align: middle;
}

.section-header p {
  margin: 0 auto 0 auto;
}

@media (min-width: 1199px) {
  .section-header p {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
}

.breadcrumbs h1 {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  font-family: 'Inter';
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
}

.breadcrumbs ol a {
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}

/*--------------------------------------------------------------
# Breadcrumbs_business
--------------------------------------------------------------*/
.breadcrumbs_business {
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs_business:before {
  content: "";
  /* background-color: rgba(0, 0, 0, 0.6); */
  position: absolute;
  inset: 0;
}

.breadcrumbs_business h2 {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  font-family: 'Inter';
}

.breadcrumbs_business ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
}

.breadcrumbs_business ol a {
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}

.breadcrumbs_business ol a:hover {
  text-decoration: underline;
}

.breadcrumbs_business ol li+li {
  padding-left: 10px;
}

.breadcrumbs_business ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.3s;
  border: 2px solid var(--color-primary);
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: transparent;
  border: 2px solid var(--color-primary);
}

.scroll-top i:hover {
  color: var(--color-primary);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1.5s linear infinite;
  animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

.navbar>.container {
  align-items: baseline !important;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/


@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

@media (max-width: 1536px) {
  .bmd p {
    font-size: 12px !important;
  }

  .bmd h2 {
    padding-top: 10px !important;
  }

  .bmd p {
    padding: 0px 3px 0px 3px !important;
  }

  .bmd a {
    margin-top: -10px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .overlay-slide p {
    font-size: 10px !important;
  }

  .bd-overlay-stripe h2 {
    font-size: 14px !important;
  }
}

@media (min-width: 2560px) {
  .overlay-slide div {
    bottom: 15% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 3840px) {
  .overlay-slide div {
    bottom: 10% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 4096px) {
  .overlay-slide div {
    bottom: 10% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .overlay-slide div {
    bottom: 20% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 361px) and (max-width: 480px) {
  .overlay-slide div {
    bottom: 15% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .overlay-slide div {
    bottom: 10% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-invert-overlap {
    filter: invert(1);
  }

  .contact-noninvert-overlap {
    filter: none;
  }
}

@media (min-width: 768px) {
  .navPosition {
    position: absolute;
    z-Index: 999;
    left: 0;
    transform: translate(0%, 50%);
    width: 100%;
  }
}

@media (min-width: 820px) and (max-width: 1023px) {
  .navbar-expand-lg .navbar-collapse {
    margin-left: 30px;
  }

  .home-banner {
    width: 98% !important;
    height: 22vh !important;
    min-height: 490px !important;
  }

  .service-contact-banner {
    width: 100%;
    /* min-height: 490px; */
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 57%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }
  
  .aes-circle {
    position: absolute;
    top: 50%;
    left: 50vw;  
    transform: translate(-50%, 100%) !important;    
  }

  .aes-circle-arrow {
    background-color: #FFF !important;
    color: #EB1F27 !important;
    border-radius: 100% !important;
    position: absolute;
    top: 50%;
    left: 50vw;   
    transform: translate(-50%, 580%) !important;   
    background: transparent !important;
  }

  .aesl-services-list .aesl-services-list-item .aesl-services-list-item-title {
    padding: 30px !important;
  }

  .footer .footer-content .footer-menu-links-left h1 {
    font-size: 40px !important;
    font-weight: 700;
  }

  .overlay-slide div {
    bottom: 15% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .navbar-expand-lg .navbar-collapse {
    margin-left: 30px;
  }

  .home-banner {
    width: 98% !important;
    height: 22vh !important;
    min-height: 490px !important;
  }

  .service-contact-banner {
    width: 100%;
    /* min-height: 490px; */
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 58%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, 100%) !important;
    left: 50vw;
  }

  .aes-circle-arrow {
    background-color: #FFF !important;
    color: #EB1F27 !important;
    border-radius: 100% !important;
    /* transform: translate(1510%, 1480%) !important; */
    position: absolute;
    top: 50%;
    transform: translate(-50%, 580%) !important;
    left: 50vw;
    background: transparent !important;
  }

  .aesl-services-list .aesl-services-list-item .aesl-services-list-item-title {
    padding: 30px !important;
  }

  .footer .footer-content .footer-menu-links-left h1 {
    font-size: 40px !important;
    font-weight: 700;
  }

  .overlay-slide div {
    bottom: 15% !important;
  }

  .overlay-slide:hover div {
    top: 0 !important;
    bottom: 0 !important;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
.aes-navbar-items.collapse.show ul,
.aes-navbar-items.collapsing ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}

.aes-navbar-items.collapse.show ul .nav-item.active,
.aes-navbar-items.collapsing ul .nav-item.active {
  width: 100%;
  border-radius: 4px;
  background-color: #E71E25;
  padding: 5px 10px;
}

.aes-navbar-items.collapse.show ul .nav-item.active .nav-link,
.aes-navbar-items.collapsing ul .nav-item.active .nav-link {
  color: white !important;
  text-align: center;
}

@media (max-width: 1279px) {}

/*--------------------------------------------------------------
# Get Started Section
--------------------------------------------------------------*/
.get-started .content {
  padding: 30px 0;
}

.get-started .content h3 {
  font-size: 36px;
  color: var(--color-secondary);
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
}

.get-started .content h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

/* .get-started .content p {
  font-size: 14px;
} */

.get-started .nodejs-email-form {
  background: #fff;
  padding: 30px;
  height: 100%;
}

@media (max-width: 575px) {
  .get-started .nodejs-email-form {
    padding: 20px;
  }

  .red-tile-icon-fontsize {
    font-size: 100px !important;
  }
}

.get-started .nodejs-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-started .nodejs-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-started .nodejs-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .nodejs-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .nodejs-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.get-started .nodejs-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.get-started .nodejs-email-form input,
.get-started .nodejs-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.get-started .nodejs-email-form input:focus,
.get-started .nodejs-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-started .nodejs-email-form input {
  padding: 12px 15px;
}

.get-started .nodejs-email-form textarea {
  padding: 12px 15px;
}

/* .get-started .nodejs-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
} */

/* .get-started .nodejs-email-form button[type=submit]:hover {
  background: #dc2730;
} */

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Constructions Section
--------------------------------------------------------------*/
.constructions .card-item {
  border: 1px solid rgba(82, 86, 94, 0.2);
  background: #fff;
  position: relative;
  border-radius: 0;
}

.constructions .card-item .card-bg {
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.constructions .card-item .card-body {
  padding: 30px;
}

.constructions .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--color-secondary);
}

.constructions .card-item p {
  color: var(--color-secondary);
  margin: 0;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  padding: 40px;
  /* background: #fff; */
  background: #f5f6f7;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #ebebed;
}

/* .services .service-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-bottom: 50px;
} */

.services .service-item .icon i {
  color: var(--color-secondary);
  font-size: 40px;
  /* transition: ease-in-out 0.3s; */
  z-index: 2;
  position: relative;
  line-height: 1.8;
}

/* .services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #f0f1f2;
  border-radius: 50px;
  z-index: 1;
  top: 10px;
  right: -15px;
  transition: 0.3s;
} */

.services .service-item h3 {
  color: var(--color-default);
  /* font-weight: 700; */
  margin: 0 0 20px 0;
  /* padding-bottom: 8px; */
  /* font-size: 22px; */
  position: relative;
  display: inline-block;
  /* border-bottom: 4px solid #ebebed; */
  /* transition: 0.3s; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 140%;
}

.services .service-item p {
  /* line-height: 24px;
  font-size: 14px; */
  margin-bottom: 0;
  border-top: 2px solid #ebebed;
  padding-top: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 130%;
}

.services .service-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: var(--color-primary);
}

/* .services .service-item:hover .icon:before {
  background: var(--color-primary);
} */

.services .who-we-are-card-item:hover h3 {
  /* border-color: var(--color-primary); */
  color: #fff;
  transition: 0.5s;
}

/* .services .service-item .icon:hover i {
  color: #fff;
} */

.services .who-we-are-card-item div {
  transition: 0.5s;
}

.services .who-we-are-card-item:hover div {
  background: var(--color-primary);
  color: #fff;
  /* transition: 0.5s; */
}

.services .who-we-are-card-item:hover i {
  /* background: var(--color-primary); */
  color: #fff;
  transition: 0.5s;
}

.esg-strategy-card-item {
  transition: 0.5s !important;
}

.esg-strategy-card-item:hover {
  background: var(--color-primary) !important;
  color: #fff !important;
  transition: 0.5s;
}

.esg-strategy-card-item:hover h3 {
  color: #fff !important;
  transition: 0.5s;
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  padding: 15px 0;
  transition: 0.3s;
  color: var(--color-secondary);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid #e2e4e6;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 575px) {
  .features .nav-link h4 {
    font-size: 16px;
  }
}

.features .nav-link:hover {
  color: var(--color-primary);
}

.features .nav-link.active {
  color: var(--color-primary);
  background-color: transparent;
  border-color: var(--color-primary);
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  font-weight: 700;
  font-size: 32px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.features .tab-pane h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-top: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-primary);
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Our Projects Section
--------------------------------------------------------------*/
.projects .portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
  border-bottom: 2px solid #f5f6f7;
}

.projects .portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  /* padding: 0; */
  /* font-size: 18px;
  font-weight: 500; */
  /* margin: 0 10px;
  line-height: 1; */
  /* margin-bottom: 5px; */
  /* transition: all 0.3s ease-in-out; */
  padding: 15px 10px 15px 10px;
  width: 175px;

  font-family: 'Inter';
  font-style: normal;
  /* font-weight: 300; */
  font-size: 18px;
  line-height: 18px;
}

.projects .portfolio-flters li:hover,
.projects .portfolio-flters li.filter-active {
  display: inline-block;
  color: var(--color-primary);
  background-color: pink;
  /* padding: 15px; */
  /* width: 150px; */
  border-bottom: 2px solid var(--color-primary);
  font-weight: bold;
}

.projects .portfolio-flters li:first-child {
  margin-left: 0;
}

.projects .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .projects .portfolio-flters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

@media (max-width: 1280px) {
  .projects .portfolio-flters li {
    margin: 0;
    cursor: pointer;
    display: inline-block;
    padding: 15px 10px 15px 10px;
    width: 155px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
  }
}

.projects .portfolio-content {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.heritages .heritage-content {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.projects .portfolio-content img {
  transition: 0.3s;
}

.heritages .heritage-content img {
  transition: 0.3s;
}

.projects .portfolio-content .portfolio-info {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
}

.heritages .heritage-content .heritage-info {
  position: absolute;
  inset: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  padding: 15px;
}

.projects .portfolio-content .portfolio-info h4 {
  font-size: 18px;
  padding: 5px 10px;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  background-color: var(--color-primary);
}

.heritages .heritage-content .heritage-info h4 {
  opacity: 1 !important;
  font-size: 18px;
  padding: 5px 10px;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  background-color: var(--color-primary);
}

.projects .portfolio-content .portfolio-info p {
  position: absolute;
  bottom: 10px;
  text-align: center;
  display: inline-block;
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}

.heritages .heritage-content .heritage-info p {
  opacity: 0 !important;
  position: absolute;
  /* bottom: 50px; */
  top: 50%;
  text-align: center;
  display: inline-block;
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  transition: all ease-in-out 0.3s;
  padding: 0 4px 0 4px;
}

.projects .portfolio-content .portfolio-info .preview-link,
.projects .portfolio-content .portfolio-info .details-link {
  position: absolute;
  left: calc(50% - 20px);
  font-size: 26px;
  top: calc(50% - 14px);
  color: #fff;
  transition: 0.3s;
  line-height: 1.2;
}

.projects .portfolio-content .portfolio-info .preview-link:hover,
.projects .portfolio-content .portfolio-info .details-link:hover {
  color: var(--color-primary);
}

.projects .portfolio-content .portfolio-info .details-link {
  left: 50%;
  font-size: 34px;
  line-height: 0;
}

.projects .portfolio-content:hover .portfolio-info {
  opacity: 1;
}

.heritages .heritage-content:hover .heritage-info {
  background: rgba(0, 0, 0, 0.6);
}

.heritages .heritage-content:hover .heritage-info p {
  opacity: 1 !important;
  transform: translate(0%, -50%);
}

.heritages .heritage-content:hover .heritage-info p i {
  transition: 0.3s;
}

.heritages .heritage-content:hover .heritage-info p i:hover {
  color: var(--color-primary) !important;
}

.projects .portfolio-content:hover img {
  transform: scale(1.1);
}

.heritages .heritage-content:hover img {
  transform: scale(1.1);
}


/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 30px;
  margin: 80px 15px 30px 0;
  min-height: 150px;
  box-shadow: 0px 0px 25px rgba(82, 86, 94, 0.1);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 140px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  /* margin: 10px 0 5px 50px; */
  /* color: #000; */
}

.testimonials .testimonial-item h4 {
  font-size: 16px;
  /* color: Var(--color-secondary); */
  margin: 0;
  font-weight: 600;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #ffd565;
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px 50px;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d5d7da;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }

  .justify-content-class {
    justify-content: flex-start;
  }

  /* .img-bg {
    min-height: 230px !important;
  } */
}

/*--------------------------------------------------------------
# Alt Services Section
--------------------------------------------------------------*/
.alt-services .img-bg,
.aes-services-red-bg .img-bg,
.furniture-home-decor-services-red-bg .img-bg {
  background-size: cover;
  background-position: center center;
  min-height: 400px;
}

.img-bg {
  background-size: cover !important;
}

.alt-services h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.aes-services-red-bg h3,
.furniture-home-decor-services-red-bg h3 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.alt-services h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.aes-services-red-bg h3:after,
.furniture-home-decor-services-red-bg h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-secondary);
  left: 0;
  bottom: 0;
}

.alt-services .icon-box {
  margin-top: 50px;
}

.alt-services .icon-box i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 25px;
  font-size: 28px;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  line-height: 0;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
  background-color: var(--color-primary);
  ;
  transition: 0.3s;
}

.alt-services .icon-box:hover i {
  background-color: var(--color-primary);
  color: #fff;
}

.alt-services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.alt-services .icon-box h4 a {
  color: #000;
  transition: 0.3s;
}

.alt-services .icon-box h4 a:hover {
  color: var(--color-primary);
}

.alt-services .icon-box p {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about h2 {
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-secondary);
  margin: 30px 0;
}

@media (min-width: 991px) {
  .about h2 {
    max-width: 65%;
    margin: 0 0 80px 0;
  }
}

.about .our-story {
  padding: 40px;
  background-color: #f5f6f7;
}

@media (min-width: 991px) {
  .about .our-story {
    padding-right: 35%;
  }
}

.about .our-story h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #838893;
}

.about .our-story h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.about .our-story p:last-child {
  margin-bottom: 0;
}

.about ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.about ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.about ul i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-primary);
}

.about .watch-video i {
  font-size: 32px;
  transition: 0.3s;
  color: var(--color-primary);
}

.about .watch-video a {
  font-weight: 600;
  color: var(--color-secondary);
  margin-left: 8px;
  transition: 0.3s;
}

.about .watch-video:hover a {
  color: var(--color-primary);
}

.about .about-img {
  min-height: 600px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  .about .about-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/*--------------------------------------------------------------
# small sm ≥576px
--------------------------------------------------------------*/
@media(min-width: 767px) {
  .vision-transform {
    transform: translate(0%, 100%);
  }
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter .stats-item {
  background: #fff;
  box-shadow: 0px 0 30px rgba(82, 86, 94, 0.05);
  padding: 30px;
}

.stats-counter .stats-item i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: var(--color-primary);
}

.stats-counter .stats-item span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: var(--color-secondary);
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Our Team Section
--------------------------------------------------------------*/
.team .member {
  position: relative;
}

.team .member .member-img {
  margin: 0 80px;
  /* border-radius: 50%; */
  overflow: hidden;
  position: relative;
}

@media (max-width: 1024px) {
  .team .member .member-img {
    margin: 0 60px;
  }
}

.team .member .member-img img {
  position: relative;
  z-index: 1;
}

.team .member .member-img .social {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding-bottom: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.team .member .member-img .social a {
  transition: 0.3s;
  color: #fff;
  font-size: 20px;
  margin: 0 8px;
}

.team .member .member-img .social a:hover {
  color: var(--color-primary);
}

.team .member .member-info {
  margin-top: 30px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 6px;
  font-size: 18px;
  color: var(--color-secondary);
}

.team .member .member-info span {
  font-style: italic;
  display: block;
  font-size: 15px;
  color: #838893;
  margin-bottom: 10px;
}

.team .member .member-info p {
  margin-bottom: 0;
  font-size: 14px;
}

.team .member:hover .member-img .social {
  padding-bottom: 0;
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Servie Cards Section
--------------------------------------------------------------*/
/* .services-cards {
  background-color: var(--color-primary);
} */

.services-cards h3 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.services-cards p {
  font-size: 16px;
}

.services-cards ul li {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 10px;
}

.services-cards ul li i {
  font-size: 16px;
  color: var(--color-primary);
  margin-right: 6px;
}

.esg-mission-cards ul li {
  text-align: left;
  font-size: 16px;
  padding-top: 20px;
}


/*--------------------------------------------------------------
# Projet Details Section
--------------------------------------------------------------*/
.project-details .portfolio-details-slider img {
  width: 100%;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

.project-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.project-details .swiper-button-prev,
.project-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.project-details .swiper-button-prev:after,
.project-details .swiper-button-next:after {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.project-details .swiper-button-prev:hover:after,
.project-details .swiper-button-next:hover:after {
  background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 575px) {

  .project-details .swiper-button-prev,
  .project-details .swiper-button-next {
    display: none;
  }
}

.project-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.project-details .portfolio-info h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.project-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.project-details .portfolio-info ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.project-details .portfolio-info ul strong {
  text-transform: uppercase;
  font-weight: 400;
  color: #838893;
  font-size: 14px;
}

.project-details .portfolio-info .btn-visit {
  padding: 8px 40px;
  background: var(--color-primary);
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
}

.project-details .portfolio-info .btn-visit:hover {
  background: #ffc019;
}

.project-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.project-details .portfolio-description p {
  padding: 0;
}

.project-details .portfolio-description .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: #f5f6f7;
  height: 100%;
  margin-bottom: 50px;
}

.project-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.project-details .portfolio-description .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 5px 0;
  padding-top: 20px;
}

.project-details .portfolio-description .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left,
.project-details .portfolio-description .testimonial-item .quote-icon-right {
  color: #ffd565;
  font-size: 26px;
  line-height: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.project-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.project-details .portfolio-description .testimonial-item p {
  font-style: italic;
  margin: 0 0 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d5d7da;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #d9e3e8;
  margin: 20px 0;
  color: var(--color-secondary);
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 20px 0 30px 0;
}

.contact .info-item i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 34px;
  line-height: 0;
  color: var(--color-primary);
  /* border-radius: 50%;
  border: 2px dotted #ffd565; */
}

.contact .info-item h3 {
  font-size: 18px;
  /* color: #6c757d; */
  font-weight: 600;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
}

.contact .nodejs-email-form {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 30px;
}

.contact .nodejs-email-form .form-group {
  padding-bottom: 20px;
}

.contact .nodejs-email-form .error-message {
  display: none;
  color: var(--color-secondary);
  background: #f5aab7;
  text-align: center;
  padding: 15px;
  /* font-weight: 600; */
}

.contact .nodejs-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .nodejs-email-form .sent-message {
  display: none;
  color: var(--color-secondary);
  background: #d2f2d4;
  text-align: center;
  padding: 15px;
  /* font-weight: 600; */
}

.contact .nodejs-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .nodejs-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--color-primary);
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .nodejs-email-form input,
.contact .nodejs-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .nodejs-email-form input:focus,
.contact .nodejs-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .nodejs-email-form input {
  height: 44px;
}

.contact .nodejs-email-form textarea {
  padding: 10px 12px;
}

/* .contact .nodejs-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
} */

/* .contact .nodejs-email-form button[type=submit]:hover {
  background: #dc2730;
} */

.contact-tile {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Recent Blog Posts Section
--------------------------------------------------------------*/
.recent-blog-posts .post-item {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
  transition: 0.3s;
}

.recent-blog-posts .post-item .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-item .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--color-primary);
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.recent-blog-posts .post-item .post-content {
  padding: 30px;
}

.recent-blog-posts .post-item .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.recent-blog-posts .post-item .meta i {
  font-size: 16px;
  color: var(--color-primary);
}

.recent-blog-posts .post-item .meta span {
  font-size: 15px;
  color: #838893;
}

.recent-blog-posts .post-item hr {
  color: #888;
  margin: 20px 0;
}

.recent-blog-posts .post-item .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: #838893;
}

.recent-blog-posts .post-item .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.recent-blog-posts .post-item:hover .post-title,
.recent-blog-posts .post-item:hover .readmore {
  color: var(--color-primary);
}

.recent-blog-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  overflow-x: hidden;
  padding: 0;
}

.hero .carousel {
  width: 100%;
  min-height: 100vh;
  padding: 80px 0;
  margin: 0;
  position: relative;
}

.hero .carousel-item {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1;
  transition-duration: 0.4s;
}

.hero .carousel-item::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
}

.hero .info {
  position: absolute;
  inset: 0;
  z-index: 2;
}

@media (max-width: 768px) {
  .hero .info {
    padding: 0 50px;
  }
}

.hero .info h1 {
  color: #fff;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 110%;
}

.hero .info h1:after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bmd-count-divider p:before {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .hero .info h1 {
    font-size: 30px;
  }

  .breadcrumbs h1 {
    font-size: 25px;
  }

  .content h2 {
    font-size: 24px;
  }

  .services .service-item span {
    font-size: 30px !important;
  }

  .services .service-item h3 {
    font-size: 22px;
  }

  .alt-services h3,
  .aes-services-red-bg h3,
  .furniture-home-decor-services-red-bg h3 {
    font-size: 22px;
  }

  .scorecard {
    font-size: 30px !important;
  }

  .message {
    font-size: 18px !important;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .scorecard {
    font-size: 30px !important;
  }

  .footer .footer-content h4 {
    font-size: 14px !important;
  }

  .heritages .heritage-content .heritage-info p {
    font-size: 12px;
  }

  .scorecard-text {
    font-size: 14px !important;
  }

  .esg-mission-cards ul li {
    font-size: 14px !important;
  }
}

@media (min-width: 769px) and (max-width: 1536px) {
  .footer .footer-content .footer-info h3 {
    font-size: 23px !important;
  }

  .scorecard-text {
    font-size: 14px !important;
  }
}

.hero .info p {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.hero .info .btn-get-started {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  /* border-radius: 50px; */
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  border: 2px solid var(--color-primary);
  margin-top: 50px;
  background-color: var(--color-primary);
}

.hero .info .btn-get-started:hover {
  color: var(--color-primary);
  background: transparent;
}

.hero .carousel-control-prev {
  justify-content: start;
}

@media (min-width: 640px) {
  .hero .carousel-control-prev {
    padding-left: 15px;
  }
}

.hero .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .hero .carousel-control-next {
    padding-right: 15px;
  }
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heritage .carousel-control-next-icon,
.heritage .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.heritage .carousel-control-next-icon:hover,
.heritage .carousel-control-prev-icon:hover {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  z-index: 3;
  transition: 0.3s;
}

.heritage .carousel-control-prev,
.heritage .carousel-control-next {
  z-index: 3;
  transition: 0.3s;
}

.hero .carousel-control-prev:focus,
.hero .carousel-control-next:focus {
  opacity: 0.5;
}

.heritage .carousel-control-prev:focus,
.heritage .carousel-control-next:focus {
  opacity: 0.5;
}

.hero .carousel-control-prev:hover,
.hero .carousel-control-next:hover {
  opacity: 0.9;
}

.heritage .carousel-control-prev:hover,
.heritage .carousel-control-next:hover {
  opacity: 0.9;
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: #838893;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: var(--color-secondary);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: var(--color-primary);
  color: #fff;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: var(--color-white);
}

/*--------------------------------------------------------------
# Blog Posts List
--------------------------------------------------------------*/
.blog .posts-list .post-item {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
  transition: 0.3s;
}

.blog .posts-list .post-img img {
  transition: 0.5s;
}

.blog .posts-list .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--color-primary);
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.blog .posts-list .post-content {
  padding: 30px;
}

.blog .posts-list .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.blog .posts-list .meta i {
  font-size: 16px;
  color: var(--color-primary);
}

.blog .posts-list .meta span {
  font-size: 15px;
  color: #838893;
}

.blog .posts-list p {
  margin-top: 20px;
}

.blog .posts-list hr {
  color: #888;
  margin-bottom: 20px;
}

.blog .posts-list .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: #838893;
}

.blog .posts-list .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.blog .posts-list .post-item:hover .post-title,
.blog .posts-list .post-item:hover .readmore {
  color: var(--color-primary);
}

.blog .posts-list .post-item:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-secondary);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(82, 86, 94, 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-primary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: #6c757d;
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: var(--color-primary);
}

.blog .blog-details .meta-top a {
  color: #6c757d;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  transition: 0.3s;
}

.blog .blog-details .meta-top a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(82, 86, 94, 0.15);
}

.blog .blog-details .meta-bottom i {
  color: #838893;
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(82, 86, 94, 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(82, 86, 94, 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(108, 117, 125, 0.8);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar {
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
}

.blog .sidebar .sidebar-item+.sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid rgba(82, 86, 94, 0.3);
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type=text]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: var(--color-primary);
  color: var(--color-secondary);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #dc2730;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: rgba(54, 77, 89, 0.4);
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  max-width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(54, 77, 89, 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #838893;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid rgba(131, 136, 147, 0.4);
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: var(--color-secondary);
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(131, 136, 147, 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-secondary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(82, 86, 94, 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(254, 185, 0, 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(254, 185, 0, 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  color: var(--color-secondary);
  background-color: var(--color-primary);
}

/* .our-values-bgimg {     
  background: url("../img/anwar-cloth.jpg") center no-repeat;    
  background-position: center;  
  background-size: cover; 
} */

.career-culture-bgimg {
  /* background: url("../img/career/background.png") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.our-mission-bgimg {
  /* background: url("../img/about-us/about-home/mission-background.png") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.tech-mission-vision-bgimg {
  /* background: url("../img/business/technology/overview/background.png") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-real-estate-mission-vision-bgimg {
  /* background: url("../img/business/real-estate/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-bmd-bgimg {
  /* background: url("../img/business/bmd/factory.jpg") top center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-textile-bgimg {
  /* background: url("../img/business/textile/background.png") top center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-real-estate-bgimg {
  /* background: url("../img/business/real-estate/background.jpg") top center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-bmd-acl-bgimg {
  /* background: url("../img/business/bmd/bmd-acl/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-furniture-home-decor-bgimg {
  /* background: url("../img/business/furniture-home-decor/background.jpg") top no-repeat; */
  background-position: top;
  background-size: cover;
}

.bd-bmd-acsl-bgimg {
  /* background: url("../img/business/bmd/bmd-acsl/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-bmd-ail-bgimg {
  /* background: url("../img/business/bmd/bmd-ail/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-bmd-a1-bgimg {
  /* background: url("../img/business/bmd/bmd-a1polymer/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-bmd-ag-bgimg {
  /* background: url("../img/business/bmd/bmd-agl/background.jpg") center no-repeat; */
  background-position: center;
  background-size: cover;
}

.bd-automobile-bgimg {
  background-position: center;
  background-size: cover;
}

.bd-automobile-service-bgimg {
  background-position: center;
  background-size: cover;
}

.our-values-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.a1-cc-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.9);
  margin-top: -80px;
  margin-bottom: -80px;
}

.ag-cc-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.automobile-achievement-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.7);
  margin-top: -80px;
  margin-bottom: -80px;
}

.automobile-heritage-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.tech-mission-vision-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.tech-why-us-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-real-estate-mission-vision-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.career-culture-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-acl-committed-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.7);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-furniture-home-decor-committed-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.7);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-acsl-commited-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.9);
  margin-top: -80px;
  margin-bottom: -80px;
}

.our-mission-bgimg-overlay {
  background-color: rgba(232, 44, 76, 0.3);
  margin-top: -80px;
  margin-bottom: -80px;
}

#find-us-acsl:before {
  content: "";
  background: rgba(56, 49, 51, 0.8);
  position: absolute;
  inset: 0;
}

#find-us-acsl {
  background-size: cover;
  position: relative;
}

#find-us-ail:before {
  content: "";
  background: rgba(56, 49, 51, 0.3);
  position: absolute;
  inset: 0;
}

#find-us-ail {
  background-size: cover;
  position: relative;
}

#find-us-a1l:before {
  content: "";
  background: rgba(56, 49, 51, 0.9);
  position: absolute;
  inset: 0;
}

#find-us-real-estate:before {
  content: "";
  background: rgba(56, 49, 51, 0.8);
  position: absolute;
  inset: 0;
}

#find-us-furniture-home-decor:before {
  content: "";
  background: rgba(56, 49, 51, 0.7);
  position: absolute;
  inset: 0;
}

#find-us-a1l {
  background-size: cover;
  position: relative;
}

#find-us-real-estate {
  background-size: cover;
  position: relative;
}

#find-us-furniture-home-decor {
  background-size: cover;
  position: relative;
}

.esg-mission-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.6);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-ail-snapshot-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.6);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-a1-snapshot-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-ag-snapshot-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.6);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-score-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.6);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-textile-score-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.7);
  margin-top: -80px;
  margin-bottom: -80px;
}


.bd-real-estate-score-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.bd-bmd-acsl-snapshot-bgimg-overlay {
  background-color: rgba(56, 49, 51, 0.8);
  margin-top: -80px;
  margin-bottom: -80px;
}

.esg-mission-bgimg {
  background-position: center;
  background-size: cover;
}

.esg-strategy-bgimg {
  background-position: center;
  background-size: cover;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  color: #fff;
  padding: 80px 0 60px 0;
  background-color: #000;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Eurostile';
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer .footer-content .footer-info p {
  line-height: 24px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: #fff;
}

.footer .footer-content .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  margin-right: 8px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  text-decoration: none;
}

.social-network .social-network-links a {
  font-size: 48px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  margin-right: 8px;
  border-radius: 100px;
  width: 96px;
  height: 96px;
  transition: 0.3s;
}

.leadership .leadership-links a {
  font-size: 26px;
  display: inline-block;
  line-height: 1;
  margin-right: 8px;
  border-radius: 100px;
  transition: 0.3s;
}

.footer .footer-content .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}

.social-network .social-network-links a:hover {
  background: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}

.leadership .leadership-links a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.footer .footer-content h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul li {
  padding: 8px 0;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 45px;
  text-decoration: none;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  color: #949494;
}

.footer .footer-content .footer-links ul a:hover {
  color: #fff;
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: #fff;
}

.footer .footer-legal .credits a {
  color: var(--color-primary);
}

time {
  color: var(--color-primary);
}

.footer .footer-legal .container {
  padding-left: 10px;
  padding-bottom: 0;
}

.footer .footer-legal .copyright {
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 400;
  color: #7C7C7C;
}

.map-container {
  width: 100%;
}

.map-container iframe {
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative;
  /* IE needs a position other than static */
}

.map-container iframe.clicked {
  pointer-events: auto;
}

div.dataTables_filter input {
  height: 50px;
  outline: none;
}

div.dataTables_length {
  margin-bottom: 20px;
}

div.dataTables_length select {
  width: 100px;
  height: 50px;
  outline: none;
}

.overlay-slide {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.overlay-slide:hover div {
  transform: translate(0, 0);
  transition: 0.5s;
  top: 0;
  bottom: 0;
}

.overlay-slide div {
  position: absolute;
  box-sizing: border-box;
  bottom: 20%;
  transform: translate(0, 100%);
  background-color: rgba(232, 44, 76, 0.6);
  text-align: center;
  transition: 0.5s;
  right: 0;
  left: 0;
  top: 0;
}

.overlay-slide h2 {
  padding-top: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.overlay-slide p {
  font-size: 14px;
  color: #fff;
  padding: 10px 2px 10px 2px;
}

.overlay-slide a {
  box-sizing: border-box;
  text-decoration: none;
  display: block;
  color: #fff;
  background-color: var(--color-default);
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 120px;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  font-weight: bold;
}

.bmd-overlay-stripe {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.bmd-overlay-stripe h2 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  transform: translate(0%, -50%);
}

.bmd-overlay-stripe div {
  position: absolute;
  top: 0;
  bottom: 0;
  color: #f1f1f1;
  background-color: rgba(56, 49, 51, 0.8);
  width: 100%;
  transition: .5s ease;
  opacity: 0.75;
  color: white;
  font-size: 18px;
  padding: 35% 0 0 0;
  text-align: center;
}

.bmd-overlay-stripe:hover div {
  transform: translate(0, 0);
  transition: 0.5s;
  opacity: 0;
}

.business-bmd-tile:hover {
  scale: 1.1;
}

/*
*****************************************
*/

.swiper-slide-x {
  transition: all 200ms linear;
  transform: scale(0.9);

  &.swiper-slide-active {
    transform: scale(1.1);
  }
}

.google-map-overlay {
  z-index: 1;
  background-color: rgba(56, 49, 51, 0.7);
  overflow: hidden;
  position: absolute;
  top: 0;
  text-align: center;
}

.google-map-overlay-left-right-home {
  left: 0;
  right: 0;
}

.google-map-overlay-left-right-contact {
  left: 10px;
  right: 10px;
}

.google-map-overlay span {
  font-size: 18px;
  font-weight: 700;
  color: white;
  top: 50%;
  position: relative;
}

.glightbox-clean .gslide-title {
  font-family: 'Inter';
}

.glightbox-clean .gslide-desc {
  font-family: 'Inter';
  font-size: 16px !important;
}

.scorecard {
  font-size: 60px;
  font-weight: 600;
}

.scorecard-text {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.message {
  font-size: 22px;
}

table.dataTable.dtr-inline.collapsed tbody td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
  background-color: var(--color-primary) !important;
}

.red-tile-icon-fontsize {
  font-size: 125px;
}

@media (min-width: 992px) {
  .right-bar {
    border-right: 1px solid;
  }

  .img-bg {
    background-size: contain !important;
  }
}

@media (min-width: px) {
  .right-bar {
    border-right: 1px solid;
  }

  .img-bg {
    background-size: contain !important;
  }
}

@media (min-width: 768px) {
  .right-bar-tech {
    border-right: 2px solid #c4c4c5;
  }
}

.btn {
  border-radius: 0 !important;
  line-height: 2.0 !important;
}

.serviceItem .faArrowRight {
  color: #E7E7E7;
  background-color: #B4B4B4;
  border-radius: 100%;
  border-color: #C1C2C5;
}

.serviceItem:hover .faArrowRight {
  color: var(--color-primary);
  transform: rotate(315deg);
  background-color: #FFF;
  border-radius: 100%;
}

.insightItem:hover .faArrowRight {
  color: var(--color-primary);
  transform: rotate(315deg);
}

.faArrowRight:hover {
  transform: rotate(315deg);
}

.display {
  opacity: 1 !important;
}

.gotop {
  transition: all 0.2s;
  position: fixed;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  opacity: 0;
  outline: 0;
  cursor: pointer;
  bottom: 60px;
  right: 50px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article h4:before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #B4B4B4 !important;
  display: inline-block !important;
  border-radius: 100% !important;
  margin: 0 10px 5px 0 !important;
  vertical-align: middle !important;
}

.card {
  width: 100%;
  --bs-card-border-radius: 0% !important;
  --bs-card-inner-border-radius: 0% !important;
  border-radius: 0% !important;
}

.card .overflow {
  overflow: hidden;
}

.card .card-img-top {
  transform: scale(1);
  transition: all 1s ease;
  height: 15vw;
  object-fit: cover;
}

.card .card-img-top:hover {
  transform: scale(1.5);
  transition: all 1s ease;
}

.card .card-img-top-detail {
  height: 15vw;
  object-fit: cover;
  width: 100%;
}

.news-button-margin-left {
  margin-left: 10px;
}

.btn {
  border-radius: 0 !important;
}

.empty-color {
  color: #000000;
}

@media (min-width: 1920px) {
  .home-banner {
    width: 98% !important;
    height: 62vh !important;
    min-height: 590px !important;
  }

  .service-contact-banner {
    width: 100%;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 50%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 75px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle {
    transform: translate(70%, 25%) !important
  }

  .aes-circle-arrow {
    background-color: #FFF !important;
    color: #EB1F27 !important;
    border-radius: 100% !important;
    transform: translate(140%, -45%) !important;
    background: transparent !important;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-left: 100px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1919px) {
  .home-banner {
    width: 98% !important;
    height: 0vh !important;
    min-height: 500px !important;
  }

  .service-contact-banner {
    width: 100%;
    min-height: 490px;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 55%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle-arrow {
    background-color: #FFF;
    color: #EB1F27;
    border-radius: 100%;
    transform: translate(135%, -55%) !important;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-left: 100px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .home-banner {
    width: 98% !important;
    height: 0vh !important;
    min-height: 500px !important;
  }

  .service-contact-banner {
    width: 100%;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 50%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle-arrow {
    background-color: #FFF;
    color: #EB1F27;
    border-radius: 100%;
    transform: translate(135%, -55%) !important;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-left: 100px !important;
  }
}

.hover-underline-animation,
.hover-underline-animation-active {
  display: inline-block;
  position: relative;
  color: #EB1F27;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #EB1F27;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-active:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #EB1F27;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after,
.hover-underline-animation-active:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.btn-danger {
  background-color: #E7E7E7 !important;
  border-color: #E7E7E7 !important;
  color: #000 !important;
  width: 164px !important;
  height: 44px !important;
}

.btn-danger:hover {
  background-color: #EB1F27 !important;
  color: #FFF !important;
}

.btn-insight-cat {
  background-color: #E7E7E7 !important;
  border-color: #E7E7E7 !important;
  color: #000 !important;
}

.btn-insight-cat:hover {
  background-color: #EB1F27 !important;
  color: #FFF !important;
}

.nav-item .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.nav-item.active .nav-link,
.nav-item:active {
  color: #EB1F27 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #E7E7E7 !important;
  color: rgb(0, 0, 0, 0.8) !important;
}

.insight-head .card {
  --bs-card-spacer-y: 1rem !important;
  --bs-card-spacer-x: 0rem !important;
  border: 0 !important;
}

.insight-all .card {
  --bs-card-spacer-y: 1rem !important;
  --bs-card-spacer-x: 0rem !important;
  --bs-card-bg: transparent !important;
  border: 0 !important;
}

.insight-detail .card {
  --bs-card-spacer-y: 2rem !important;
  --bs-card-spacer-x: 0rem !important;
  --bs-card-bg: transparent !important;
  border: 0 !important;
}

.insight-detail h2::before {
  content: "";
  width: 38px;
  height: 38px;
  margin-right: 20px;
  background: var(--color-primary);
  display: inline-block;
}

.error {
  font-size: 12px;
  color: tomato;
  margin-top: 0.25rem;
}

.form-control:focus {
  border-color: tomato !important;
  box-shadow: unset !important
}

.table {
  --bs-table-border-color: #C1C2C5 !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: transparent !important;
}

.career-datatable th,
td {
  padding: 20px !important;
}

select {
  border: unset !important;
}

.search input {
  border: unset !important;
}

.service-module h1::after,
.service-client h1::after,
.career-benefits h1::after {
  content: "";
  position: absolute;
  left: 15px;
  width: 7.5%;
  border-bottom: 4px solid var(--color-primary);
  margin-top: 1.75em;
}

.page-link {
  color: var(--color-primary);
}

.page-link:hover {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.pagination {
  --bs-pagination-color: #000 !important;
  --bs-pagination-active-bg: var(--color-primary) !important;
  --bs-pagination-active-border-color: var(--color-primary) !important;
}

.jobs-pagination {
  display: flex;
  justify-content: center;
}

.jobs-pagination a {
  cursor: pointer;
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-toggler {
    display: none !important;
  }

  .navbar-nav {
    flex-direction: row !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .hide-aes-circle-mobile {
    display: none;
  }
}

/*********************************** REFACTORED CSS ***********************************/

/* FOOTER */
/* Logo-Social-Links */
.footer-logo-social-links .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 25px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .footer-logo-social-links .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
}

/* Menu-Links */
.footer-menu-links .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .footer-content .footer-menu-links-left h4 {
  font-size: 24px;
  font-weight: 300;
  color: #7C7C7C;
}

.footer .footer-content .footer-menu-links-left h1 {
  font-size: 50px;
  font-weight: 700;
}

.footer-menu-links-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 50px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .footer-menu-links .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .footer-menu-links-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .footer .footer-content .footer-menu-links-left h4 {
    font-size: 22px;
  }

  .footer .footer-content .footer-menu-links-left h1 {
    font-size: 24px;
    text-wrap: wrap;
  }

  .footer .footer-content .footer-menu-links-right {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
  }
}

/* Footer-Legal */
.footer-legal .container {
  display: flex;
  justify-content: flex-start;
  padding: 50px 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .footer-legal .container {
    justify-content: center;
  }
}

@media (min-width: 912px) and (max-width: 912px) {
  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 57%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(370%, 100%) !important;
  }

  .aes-circle-arrow {
    background-color: transparent;
    color: #EB1F27;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(1810%, 570%) !important;
  }
}

@media (min-width: 3840px) {
  .container {
    max-width: 2560px !important;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 50%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }
}

@media (min-width: 2560px) and (max-width: 3839px) {
  .container {
    max-width: 1920px !important;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 50%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }
}

/* HEADER */
/* Small screens (phones, 576px and down) */
@media (max-width: 767px) {
  .container {
    max-width: 95% !important;
  }

  .header-class {
    /* position: absolute; */
    padding: 0;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 35%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .subHeaderText {
    font-size: 16px !important;
    width: 300px !important;
  }

  .aes-circle {
    position: absolute;
    top: 50%;
    left: 50vw;
    transform: translate(-50%, 50%)
  }

  .aes-circle-arrow {
    background-color: transparent;
    color: #EB1F27;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50vw;
    transform: translate(-50%, 360%);
  }

  .home-banner,
  .service-contact-banner {
    max-width: none !important;
    height: 100% !important;
  }

  .hide-header-right-banners {
    display: none;
  }

  .hide-header-block {
    display: none;
  }

  .hide-aes-circle {
    display: none;
  }
}

/* Large screens (desktops, 769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .aes-circle {
    transform: translate(60%, 25%);
  }
}

/* Extra large screens (large desktops, 993px to 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .aes-circle {
    transform: translate(60%, 25%);
  }
}

/* Extra extra large screens (wider desktops, 1201px and up) */
@media (min-width: 1201px) {
  .header-class {
    position: relative !important;
    padding: 0 !important;
  }

  .aes-circle {
    transform: translate(70%, 25%)
  }

  .aes-circle-arrow {
    background-color: #FFF;
    color: #EB1F27;
    border-radius: 100%;
    transform: translate(140%, -45%);
    background: transparent;
  }

  .aes-circle-arrow-position {
    position: absolute;
    bottom: 40px;
    right: 20px;
  }

  .service-contact-banner {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .navbar-expand-lg .navbar-collapse {
    margin-left: 45px;
  }

  .home-banner {
    width: 98% !important;
    height: 22vh !important;
    min-height: 490px !important;
  }

  .service-contact-banner {
    width: 100%;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 55%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle {
    position: absolute;
    top: 50%;
    left: 50vw;
    transform: translate(-50%, 95%);
  }

  .aes-circle-arrow {
    background-color: #FFF;
    color: #EB1F27;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50vw;
    transform: translate(-50%, 550%);
  }
}

@media (min-width: 1201px) and (max-width: 1279px) {
  .navbar-expand-lg .navbar-collapse {
    margin-left: 45px;
  }

  .home-banner {
    width: 98% !important;
    height: 22vh !important;
    min-height: 490px !important;
  }

  .service-contact-banner {
    width: 100%;
    min-height: 490px;
  }

  .headerText,
  .headerText-service-contact {
    position: absolute !important;
    top: 55%;
    left: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 100% !important;
  }

  .headerText1,
  .headerText-service-contact1 {
    color: #000;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 800;
    line-height: 111%;
  }

  .aes-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -80%);
  }

  .aes-circle-arrow {
    background-color: #FFF;
    color: #EB1F27;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(30%, -170%);
  }
}

.subHeaderText {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
  text-underline-offset: 10px;
  line-height: 30px;
  width: 360px;
  margin-top: 30px;
}

button.gotop.display:hover {
  rotate: 360deg;
}