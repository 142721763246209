.aesl-services-list-item {
    width: 100%;
    min-height: 115px;
    padding: 20px 0;
    border-top: 1px solid #C1C2C5;
}

.aesl-services-list > .aesl-services-list-item:last-child {    
    border-bottom: 1px solid #C1C2C5;
}

.aesl-services-list .aesl-services-list-item:not(:last-child) {
    border-bottom: 0
}

.aesl-services-list .aesl-services-list-item .aesl-services-list-item-number {
    font-weight: 600;
    font-size: 50px;
    color: #D9DADC;
}
.aesl-services-list .aesl-services-list-item.is-active .aesl-services-list-item-number {
    font-weight: 700;
    color: #EB1F27;
}

.aesl-services-list .aesl-services-list-item .aesl-services-list-item-title {
    font-weight: 500;
    font-size: 24px;
    color: black;
    padding: 10px;
}
.aesl-services-list .aesl-services-list-item.is-active .aesl-services-list-item-title {
    font-weight: 700;
    font-size: 24px;
}

.aesl-services-list .aesl-services-list-item .aesl-services-list-item-description {
    margin-top: 20px;
    padding-left: 0px;
    list-style: none;
}

.aesl-services-list .aesl-services-list-item .aesl-services-list-item-description li {
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.aesl-services-list .aesl-services-list-item .aesl-services-list-item-description li::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid red;
    margin-right: 1rem;
}


.aesl-services-list .aesl-services-list-item .aesl-services-list-item-button button {
    width: 60px;
    height: 60px;
    border-radius: 50% !important;
    background-color: #C1C2C570;
    padding: 10px;
    color: #A6A6A6;
}
.aesl-services-list .aesl-services-list-item.is-active .aesl-services-list-item-button button {
    background-color: #EB1F27;
    color: #FFF;
}

@media(max-width: 380px) {
    .aesl-services-list .aesl-services-list-item .aesl-services-list-item-description li {
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
    }
    
    .aesl-services-list .aesl-services-list-item .aesl-services-list-item-description li::before {
        content: "";
        width: 15px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid red;
        margin-right: 1rem;
    }
}