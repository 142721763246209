.link-btn {
    min-width: 200px;
    padding: 16px;
    background-color: #E7E7E7;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: black;
    line-height: 36px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}
.link-btn:hover {
    background-color: #EB1F27;
    color: #FFF;
}