.menu-icon {
  display: none !important;
}

.nav-elements {
  z-index: 999 !important;
}

.nav-elements .copyright {
  display: none;
}

@media (max-width: 767px) {
  .navbar {
    height: 60px !important;
    position: relative !important
  }

  .menu-icon {
    display: block !important;
    cursor: pointer !important;
  }

  .nav-elements {
    position: absolute !important;
    right: 0 !important;
    top: 60px !important;
    background-color: #fff !important;
    width: 0px !important;
    height: calc(100vh - 60px) !important;
    transition: all 0.3s ease-in !important;
    overflow: hidden !important;

    background-image: url('../assets/images/banners/banner-left.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center bottom; */
  }

  .nav-elements .copyright {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 18px;
    font-weight: 400;
    min-width: 375px;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  .nav-elements.active {
    width: 100% !important;
  }

  .nav-elements ul {
    display: flex !important;
    flex-direction: column !important;
    margin-left: 20px;
  }

  .nav-item .nav-link {
    margin-left: 2px;
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
  }

  .nav-item.active .nav-link {
    background-color: #E71E25 !important;
    color: #fff !important;
    margin-left: 2px;
    padding: 12px;
    border-radius: 6px;
  }
}