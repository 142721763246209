.introduction-margin {
    margin-bottom: -80px;
}

.introduction-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px 0 20px;
}

.startup-manufacture-finance-title {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
}

.introduction-container {
    display: flex;
    justify-content: space-between;
    gap: 36px;
}

.introduction-left,
.introduction-middle,
.introduction-right {
    flex-basis: 33%;
    border: 1px solid #DBDBDB;
    background: rgba(193, 194, 197, 0.05);
}

.startup,
.manufacture,
.finance {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 767px) {
    .introduction-container {       
        flex-direction: column;
        flex-wrap: wrap;
    }
}